<template>
  <span>
    <b-row class="pb-2">
      <b-col>
        <ChgkplanTableInfo :games-count="items.length" :now-time="nowTime"/>
      </b-col>
    </b-row>
    <ChgkplanQuickFilter v-if="gameCounts" :now="now" :criteria="criteria" :type-items="typeItems"
                         :has-markers="hasMarkers" :game-counts="gameCounts"/>
    <ChgkplanTableList :now="now" :criteria="criteria" :items="items" :has-markers="hasMarkers"/>
  </span>
</template>

<script>
import ChgkplanQuickFilter from "./ChgkplanQuickFilter";
import ChgkplanTableList from "./ChgkplanTableList";
import ChgkplanTableInfo from "./ChgkplanTableInfo";
import moment from "moment";
import LsUtils from "../../utils/ls-utils";

export default {
  components: {ChgkplanTableInfo, ChgkplanTableList, ChgkplanQuickFilter},
  data: function () {
    return {
      now: null, nowTime: null, playerId: null, typeItems: [], items: [],
      hasMarkers: false, gameCounts: null,
      criteria: {type: null, markers: [], aegis: null, rating: null, dlMin: null, dlMax: null, editor: undefined}
    }
  },
  mounted() {
    this.refreshMarkersState();
    this.$root.$on('onChgkplanTableType', this.onChgkplanTableType);
    this.$root.$on('onMarkersToggle', this.refreshMarkersState);
  },
  methods: {
    onChgkplanTableType(filter) {
      this.now = filter.now;
      this.nowTime = moment(this.now).format('D MMMM YYYY (dd), HH:mm');
      this.playerId = filter.playerId;
      this.items = window.TOURS.filter(this.filterGame);
      this.gameCounts = this.calculateGameCounts();
      this.generateTypeItems();
    },
    refreshMarkersState() {
      this.hasMarkers = LsUtils.isActive();
      this.criteria.markers = this.hasMarkers ? LsUtils.getLs().markers : [];
      this.items.forEach(game => game.markerId = LsUtils.getMarker(game.id));
    },
    filterGame(item) {
      this.updateGame(item);
      return this.now.isBetween(item.start, item.end);
    },
    updateGame(game) {
      game._showDetails = true;
      const tillEnd = Math.floor(moment.duration(game.end.diff(this.now)).asDays());
      game.progress2 = tillEnd <= 1 ? 20 : (tillEnd > 12 ? 80 : 20 + tillEnd * 5);
      game.progress1 = 100 - game.progress2;
      game.played = this.playerId && window.PLAYERS && window.PLAYERS[this.playerId]
          && window.PLAYERS[this.playerId].includes(game.id);
      game.markerId = LsUtils.getMarker(game.id);
    },
    calculateGameCounts() {
      const r = {};
      if (!LsUtils.isActive()) {
        return r;
      }
      this.items.forEach(g => {
        const m = LsUtils.getMarker(g.id);
        if (!r[m]) r[m] = 0;
        r[m]++;
      });
      return r;
    },
    generateTypeItems() {
      const typeIds = [];
      this.items.forEach(i => {
        if (!typeIds.includes(i.typeId)) {
          typeIds.push(i.typeId);
          this.typeItems.push({value: i.typeId, text: i.typeName});
        }
      });
    }
  }
}
</script>
