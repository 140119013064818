<template>
  <b-row class="pb-2">
    <b-col cols="12" v-if="hasMarkers" class="pb-2">
      <ChgkplanQuickFilterMarker :criteria="criteria" :game-counts="gameCounts" />
    </b-col>
    <b-col cols="12">
      <b-input-group size="sm">
        <b-form-select v-model="criteria.aegis" :options="aegises"></b-form-select>
        <b-form-select v-model="criteria.rating" :options="ratings"></b-form-select>
        <b-form-select v-model="criteria.type" :options="types"></b-form-select>
        <b-form-input v-model="criteria.editor" type="text" size="sm" placeholder="Редактор"></b-form-input>
        <b-input-group-append>
          <b-button @click="onReset($event)" size="sm" type="reset">
            <b-icon-x-circle-fill></b-icon-x-circle-fill>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col cols="12" class="mt-1">
      <b-input-group size="sm" prepend="Cложность">
        <b-form-input v-model="criteria.dlMin" type="range" min="0.1" max="9.9" step="0.1"></b-form-input>
        <b-form-input v-model="criteria.dlMax" type="range" min="0.1" max="9.9" step="0.1"></b-form-input>
        <b-input-group-append is-text>
          {{criteria.dlMin ? criteria.dlMin : '0'}} - {{criteria.dlMax ? criteria.dlMax : '10'}}
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import LsUtils from "../../utils/ls-utils";
import ChgkplanQuickFilterMarker from "./ChgkplanQuickFilterMarker";

export default {
  components: {ChgkplanQuickFilterMarker},
  props: ['criteria', 'typeItems', 'markerCounts', 'hasMarkers', 'gameCounts'],
  data: function () {
    return {
      types: [
        {value: null, text: "Тип турнира"},
      ],
      aegises: [
        {value: null, text: "Эгида"},
        {value: 'maii', text: "Эгида МАИИ"},
        {value: 'mak', text: "Эгида МАК"},
        {value: 'empty', text: "Без эгид"},
      ],
      ratings: [
        {value: null, text: "Рейтингуется?"},
        {value: 'yes', text: "Да, рейтингуется"},
        {value: 'no', text: "Не рейтингуется"}
      ]
    }
  },
  created() {
    this.$nextTick(() => {
      this.types = this.types.concat(this.typeItems);
    });
  },
  methods: {
    onReset($event) {
      $event.preventDefault();
      this.criteria.markers = LsUtils.getAllMarkerTypeIds();
      this.criteria.aegis = null;
      this.criteria.rating = null;
      this.criteria.dlMin = null;
      this.criteria.dlMax = null;
      this.criteria.type = null;
      this.criteria.editor = undefined;
    }
  }
}
</script>
