<template>
  <b-table small striped hover caption-top responsive
           :items="items" :fields="fields" :tbody-tr-class="rowClass"
           :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
           :filter-function="filterTable" :filter="criteria">
    <template #cell(aegis)="{item}">
      <div v-if="item.aegises.includes('maii')" class="text-center">
        <b-img src="/images/maii.png"></b-img>
        <div class="small text-muted">
          МАИИ
        </div>
        <b-badge v-if="item.rating === 'yes'" variant="success">Рейтингуется</b-badge>
        <b-badge v-if="item.rating === 'no'" variant="secondary">Не рейтингуется</b-badge>
      </div>
      <div v-if="item.aegises.includes('mak')" class="text-center">
        <div class="small text-muted">
          МАК
        </div>
        <b-badge v-if="item.rating === 'yes'" variant="success">Рейтингуется</b-badge>
        <b-badge v-if="item.rating === 'no'" variant="secondary">Не рейтингуется</b-badge>
      </div>
    </template>
    <template #cell(name)="{item}">
      <a :href="`https://rating.chgk.info/tournament/${item.id}`" target="_blank">{{ item.id }}</a>: {{ item.name }}
      <div class="small text-muted" style="line-height: 1.1;">
        {{ item.typeName }}, {{ item.questions }} вопросов<br/>
        Длительность: {{ Math.floor(item.duration.asDays()) }} дн.<br/>
        Стоимость: {{ item.priceInfo }}
      </div>
    </template>
    <template #cell(dl)="{item}">
      <b-badge :variant="getBadgeVariant(item.dl)">{{ item.dl }}</b-badge>
      <br/>
      <b-badge v-if="item.played" variant="success">Игрался</b-badge>
    </template>
    <template #cell(startMs)="{item}">
      <div class="small">
        {{ item.start.format('D MMM') }}<br/>
        {{ item.start.format('HH:mm') }}
        <b-link class="pr-3" :href="getGoogleCalStartUrl(item)">
          <b-icon-calendar-plus></b-icon-calendar-plus>
        </b-link>
      </div>
      <div class="small text-muted" style="line-height: 1.1;">
        {{ item.start.format('dddd') }}<br/>
        {{ item.start.from(today) }}
      </div>
    </template>
    <template #cell(endMs)="{item}">
      <div class="small">
        {{ item.end.format('D MMM') }}<br/>
        {{ item.end.format('HH:mm') }}
        <b-link class="pr-3" :href="getGoogleCalEndUrl(item)">
          <b-icon-calendar-plus></b-icon-calendar-plus>
        </b-link>
      </div>
      <div class="small text-muted" style="line-height: 1.1;">
        {{ item.end.format('dddd') }}<br/>
        {{ item.end.from(today) }}
      </div>
    </template>
    <template #cell(editors)="data">
      <div class="small text-muted" style="line-height: 1.1;">
        <span v-for="(item, index) in data.value" :key="index">
          <a :href="`https://rating.chgk.info/player/${item.id}`"
             target="_blank">{{ item.name }}</a><br/>
        </span>
      </div>
    </template>
    <template #cell(orgs)="data">
      <div class="small text-muted" style="line-height: 1.1;">
        <span v-for="(item, index) in data.value" :key="index">
          <a :href="`https://rating.chgk.info/player/${item.id}`"
             target="_blank">{{ item.name }}</a><br/>
        </span>
      </div>
    </template>
    <template #row-details="row">
      <ChgkplanRowMarker v-if="hasMarkers" :game="row.item"/>
      <div class="pb-3">
        <b-progress class="mt-2" max="100" show-value>
          <b-progress-bar :value="row.item.progress1" variant="secondary">
            Начался {{ row.item.start.from(now) }}
          </b-progress-bar>
          <b-progress-bar :value="row.item.progress2" variant="dark">
            Закончится {{ row.item.end.from(now) }}
          </b-progress-bar>
        </b-progress>
      </div>
    </template>
  </b-table>
</template>

<script>
import LsUtils from '../../utils/ls-utils';
import ChgkplanRowMarker from "./ChgkplanRowMarker";
import moment from "moment";

export default {
  components: {ChgkplanRowMarker},
  props: ['now', 'criteria', 'items', 'hasMarkers'],
  data: function () {
    return {
      today: null,
      sortBy: 'endMs',
      sortDesc: false,
      fields: [
        {
          key: 'aegis',
          label: 'Эгида',
          sortable: true,
          thStyle: 'width: 90px;'
        },
        {
          key: 'name',
          label: 'Базовая информация',
          sortable: true,
          thStyle: 'width: 35%;'
        },
        {
          key: 'dl',
          label: 'Сложность',
          sortable: true
        },
        {
          key: 'startMs',
          label: 'Начало',
          sortable: true
        },
        {
          key: 'endMs',
          label: 'Конец',
          sortable: true
        },
        {
          key: 'editors',
          label: 'Редакторы',
          sortable: true
        },
        {
          key: 'orgs',
          label: 'Организаторы',
          sortable: true
        }
      ]
    }
  },
  created() {
    this.today = moment();
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return '';
      if (item.played) return 'table-success';
      if (!this.profile) return '';
      return item.cabType === 't5' ? '' : "table-" + LsUtils.CABINET_TYPES[item.cabType].variant;
    },
    getBadgeVariant(dl) {
      if (dl > 7.7) return 'dark';
      if (dl > 4.7) return 'danger';
      if (dl > 3.7) return 'primary';
      if (dl > 2.7) return 'info';
      if (dl > 0.7) return 'success';
      return 'secondary';
    },
    filterTable(row, filter) {
      let result = true;
      if (filter.aegis) result = result && (
          filter.aegis && row.aegises.includes(filter.aegis)
          || filter.aegis === 'empty' && row.aegises.length === 0
      );
      if (!result) return false;
      if (filter.rating) result = result && row.rating === filter.rating;
      if (!result) return false;
      if (filter.markers.length > 0) result = result && filter.markers.includes(row.markerId);
      if (!result) return false;
      if (filter.type) result = result && row.typeId === filter.type;
      if (!result) return false;
      if (filter.dlMin > 0) result = result && row.dl >= filter.dlMin;
      if (!result) return false;
      if (filter.dlMax > 0) result = result && row.dl <= filter.dlMax;
      if (!result) return false;
      if (filter.editor) result = result && this.contains(row.editors, filter.editor);
      return result;
    },
    contains(values, value) {
      let result = false;
      values.forEach(v => {
        result = result || v.name.toUpperCase().indexOf(value.toUpperCase()) > -1;
      });
      return result;
    },
    getGoogleCalStartUrl(item) {
      const time = item.start.format('YYYYMMDD[T]HHmm00Z')
      const dates = time + "/" + time;
      return this.getGoogleCalUrl("начало", item.name, dates, this.getDetails(item));
    },
    getGoogleCalEndUrl(item) {
      const time = item.end.format('YYYYMMDD[T]HHmm00Z')
      const dates = time + "/" + time;
      return this.getGoogleCalUrl("конец", item.name, dates, this.getDetails(item));
    },
    getGoogleCalUrl(text, name, dates, details) {
      return "https://www.google.com/calendar/render" +
          "?action=TEMPLATE" +
          "&text=" + encodeURIComponent("ЧГК | " + text + " | " + name) +
          "&dates=" + dates +
          "&details=" + encodeURIComponent(details) +
          "&location=" +
          "&sf=true" +
          "&output=xml";
    },
    getDetails(item) {
      return "<a href='https://rating.chgk.info/tournament/" + item.id + "'>" + item.name + "</a> | " +
          item.start.format('D MMM HH:mm') + " — " + item.end.format('D MMM HH:mm') + "<br/>" +
          item.typeName + " | " + item.questions + " вопросов | " +
          " Длительность: " + Math.floor(item.duration.asDays()) + " дн. <br/><br/>" +
          "Стоимость: " + item.priceInfo + "<br/><br/>" +
          "РЕДАКТОРЫ | " + this.getPeople(item.editors) + "<br/>" +
          "ОРГИ | " + this.getPeople(item.orgs);
    },
    getPeople(items) {
      let result = "";
      items.forEach(item => {
        result = result + item.name + " | ";
      });
      return result;
    }
  }
}
</script>
