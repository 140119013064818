export default class LsUtils {
    static KEY = "QUIZA";
    static DEFAULT_TYPES = [
        {id: 'm0', icon: 'circle', variant: 'dark', text: 'Нет отметки'},
        {id: 'm1', icon: 'x-circle', variant: 'secondary', text: 'Не буду играть'},
        {id: 'm2', icon: 'check-circle', variant: 'success', text: 'Играл'},
        {id: 'm3', icon: 'question-circle', variant: 'warning', text: 'Возможно сыграю'},
        {id: 'm4', icon: 'exclamation-circle', variant: 'danger', text: 'Буду играть'},
        {id: 'm5', icon: 'chat-square-text', variant: 'success', text: 'Тестировал/Редактировал'},
        {id: 'm6', icon: 'person-square', variant: 'info', text: 'Организовывал'},
        {id: 'm7', icon: 'layers-fill', variant: 'secondary', text: 'Дубликат (тот же пакет)'}
    ];

    /** LS GET **/

    static getMarkerTypes() {
        const ls = this.getLs();
        return ls && ls.types ? ls.types : this.DEFAULT_TYPES;
    }

    static getAllMarkerTypeIds() {
        return this.getMarkerTypes().map(t => t.id);
    }

    static getMarker(gameId) {
        const ls = this.getLs();
        return ls && ls.games[gameId] ? ls.games[gameId] : 'm0';
    }

    /** LS SAVE **/

    static createDefaultLs() {
        this.saveItem(this.KEY, {markers: this.getAllMarkerTypeIds(), games: {}});
    }

    static saveLsMarkers(markers) {
        const item = this.getLs();
        item.markers = markers;
        this.saveLs(item);
    }

    static saveLsGames(games) {
        const item = this.getLs();
        item.games = games;
        this.saveLs(item);
    }

    /** LS COMMON **/

    static isActive() {
        return !!this.getLs();
    }

    static getLs() {
        this.checkData();
        return this.readItem(this.KEY);
    }

    static saveLs(item) {
        this.saveItem(this.KEY, item);
    }

    /** COMMON **/

    static clear() {
        localStorage.clear();
    }

    static saveItem(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    }

    static readItem(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    /** LEGACY CHANGES **/

    static checkData() {
        let ls = this.readItem(this.KEY);
        let changed = false;
        if (ls && ls.markers) {
            const result = [];
            ls.markers.forEach(t => {
                result.push(this.convert(t));
            });
            ls.markers = result;
            changed = true;
        }
        if (ls && ls.games) {
            const games = {};
            Object.keys(ls.games).forEach(i => {
                games[i] = this.convert(ls.games[i]);
            });
            ls.games = games;
            changed = true;
        }
        if (changed) {
            this.saveLs(ls);
        }
    }

    static convert(t) {
        return t === 't5' ? 'm0' : t === 't1' ? 'm1' : t === 't4' ? 'm2' : t === 't3' ? 'm3' : t === 't2' ? 'm4' : t;
    }
}
