<template>
    <b-row>
      <b-col class="mt-1">
        <b-input-group size="sm">
          <b-form-select v-model="filter.date" :options="dates" @change="changeFilterDate"></b-form-select>
          <b-form-select v-model="filter.time" :options="times" @change="changeFilterTime"></b-form-select>
          <b-input-group-append>
            <b-button @click="onReset($event)" size="sm" type="reset">
              <b-icon-x-circle-fill></b-icon-x-circle-fill>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col sm="4" md="3" class="mt-1">
        <b-input-group size="sm">
          <b-form-input v-model="filter.playerId" type="number" size="sm" placeholder="ID игрока"></b-form-input>
          <b-input-group-append>
            <b-button @click="onSubmit($event)" size="sm" type="submit" variant="primary">
              <b-icon-search></b-icon-search>
            </b-button>
            <b-button @click="onReset($event)" size="sm" type="reset">
              <b-icon-x-circle-fill></b-icon-x-circle-fill>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      filter: {
        type: undefined,
        date: undefined,
        time: undefined,
        now: undefined,
        playerId: undefined
      },
      dates: [],
      times: []
    }
  },
  created() {
    this.reset();
    this.$nextTick(function () {
      this.submit();
    })
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.submit();
    },
    onReset($event) {
      $event.preventDefault();
      this.reset();
    },
    changeFilterType(type) {
      this.filter.type = type;
      this.submit();
    },
    changeFilterDate() {
      this.submit();
    },
    changeFilterTime() {
      this.submit();
    },
    submit() {
      this.filter.now = moment(this.filter.date + 'T' + this.filter.time);
      this.$root.$emit('onChgkplanFilterSubmit', this.filter);
    },
    reset() {
      this.generateDates();
      this.generateTimes();
      this.filter.type = 'table';
      this.filter.date = this.dates[0].value;
      this.filter.time = moment().format("HH") + ":05:00";
      this.filter.playerId = undefined;
      this.filter.now = undefined;
    },
    generateDates() {
      const now = moment();
      this.dates = [];
      for (let i = 0; i < 30; i++) {
        this.dates.push({
          value: now.format('YYYY-MM-DD'),
          text: now.format('D MMM YYYY (ddd)')
        });
        now.add(1, 'days');
      }
    },
    generateTimes() {
      this.times = [];
      for (let i = 0; i < 24; i++) {
        const t = (i < 10 ? "0" : "") + i + ":05";
        this.times.push({
          value: t + ":00",
          text: t
        });
      }
    }
  }
}
</script>
