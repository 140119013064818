<template>
  <b-container fluid>
    <b-row class="pb-2">
      <b-col cols="12" class="d-flex justify-content-center">
        <b-button-group size="sm">
          <b-button :pressed.sync="!loading && filter.type == 'graph'"
                    @click="changeFilterType('graph')"
                    :variant="!loading && filter.type == 'graph' ? 'primary' : ''">
            <b-icon-file-bar-graph-fill></b-icon-file-bar-graph-fill>
            Показать график
          </b-button>
          <b-button :pressed.sync="!loading && filter.type == 'table'"
                    @click="changeFilterType('table')"
                    :variant="!loading && filter.type == 'table' ? 'primary' : ''">
            <b-icon-table></b-icon-table>
            Показать таблицу
          </b-button>
        </b-button-group>
        <b-link class="ml-3 small text-decoration-none" v-b-toggle="'hint'">
          <b-icon-info-circle-fill></b-icon-info-circle-fill>
        </b-link>
      </b-col>
    </b-row>
    <b-row class="pb-2">
      <b-col>
        <ChgkplanHints/>
      </b-col>
    </b-row>
    <b-row class="pb-2">
      <b-col>
        <ChgkplanFilter :filter="this.filter"/>
      </b-col>
    </b-row>
    <div class="pt-5 d-flex justify-content-center" v-if="loading">
      <b-spinner variant="primary" label="Загружаем данные..."></b-spinner>
      <div class="ml-3 align-self-center">Загружаем данные...</div>
    </div>
    <ChgkplanGraph v-if="this.filter.type === 'graph'"/>
    <ChgkplanTable v-if="this.filter.type === 'table'"/>
  </b-container>
</template>

<script>
import ChgkplanHints from "../components/chgkplan/ChgkplanHints";
import ChgkplanFilter from "../components/chgkplan/ChgkplanFilter";
import ChgkplanGraph from "../components/chgkplan/ChgkplanGraph";
import ChgkplanTable from "../components/chgkplan/ChgkplanTable";
import PlanGameConverter from '../utils/plan-game-converter';
import moment from "moment";

export default {
  components: {ChgkplanTable, ChgkplanGraph, ChgkplanHints, ChgkplanFilter},
  data: function () {
    return {
      loading: false,
      filter: {
        type: undefined,
        date: undefined,
        time: undefined,
        now: undefined,
        playerId: undefined
      }
    }
  },
  mounted() {
    this.$root.$on('onChgkplanFilterSubmit', this.onChgkplanFilterSubmit);
  },
  methods: {
    changeFilterType(type) {
      this.filter.type = type;
      this.filter.now = moment(this.filter.date + 'T' + this.filter.time);
      this.onChgkplanFilterSubmit(this.filter);
    },
    onChgkplanFilterSubmit(params) {
      this.filter.type = params.type;
      this.filter.date = params.date;
      this.filter.time = params.time;
      this.filter.now = params.now;
      this.filter.playerId = params.playerId;

      const loadTours = !window.TOURS;
      const loadPlayer = this.filter.playerId
          && (!window.PLAYERS || !window.PLAYERS[this.filter.playerId]);
      this.loadData(loadTours, loadPlayer, this.filter.playerId)
          .then(loadedData => this.onLoadedData(loadedData, loadTours, loadPlayer));
    },
    loadData(loadTours, loadPlayer, playerId) {
      this.loading = true;
      const urls = [];
      if (loadTours) {
        urls.push("/api/tours.php");
      }
      if (loadPlayer) {
        urls.push("/api/player.php?playerid=" + playerId);
      }
      const promises = urls.map(url => fetch(url).then(r => r.json()));
      return Promise.all(promises);
    },
    onLoadedData(loadedData, loadTours, loadPlayer) {
      if (loadTours) {
        this.setToursData(loadedData[0]);
      }
      if (loadPlayer) {
        if (!window.PLAYERS) {
          window.PLAYERS = {};
        }
        window.PLAYERS[this.filter.playerId] = loadTours ? loadedData[1].tours : loadedData[0].tours;
      }
      this.filterType = this.filter.type;
      const event = this.filterType === 'graph' ? 'onChgkplanGraphType' : 'onChgkplanTableType';
      this.$nextTick(() => {
        this.$root.$emit(event, {now: this.filter.now, playerId: this.filter.playerId});
      });
      this.loading = false;
    },
    setToursData(toursdata) {
      window.startDateAfter = this.formatDate(toursdata.startDateAfter);
      window.endDateBefore = this.formatDate(toursdata.endDateBefore);
      window.endDateAfter = this.formatDate(toursdata.endDateAfter);
      window.TOURS = this.convertTours(toursdata.tours);
    },
    formatDate(date) {
      return moment(date).format("D MMMM YYYY (dd)");
    },
    convertTours(tours) {
      const result = [];
      tours.forEach(game => {
        result.push(PlanGameConverter.convert(game))
      });
      return result;
    }
  }
}
</script>
