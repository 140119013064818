import moment from "moment";

export default class PlanGameConverter {
    static convert(game) {
        const result = {};
        result.id = game.id;
        result.name = game.name;
        result.start = moment(game.dateStart);
        result.startMs = result.start.valueOf();
        result.end = moment(game.dateEnd);
        result.endMs = result.end.valueOf();
        result.duration = moment.duration(result.end.diff(result.start));
        result.questions = Object.values(game.questionQty).reduce((a, b) => a + b);
        result.dl = game.difficultyForecast
        result.dlGroup = this.getDlGroup(game.difficultyForecast);
        result.orgs = [];
        this.convertPlayers(game.orgcommittee, result.orgs);
        result.editors = [];
        this.convertPlayers(game.editors, result.editors);
        result.typeId = game.type.id;
        result.typeName = game.type.name;
        result.priceInfo = this.getPriceInfo(game);
        result.aegises = [];
        result.rating = undefined;
        if (game.maiiAegis) {
            result.aegises.push('maii');
            result.rating = game.maiiRating ? 'yes' : 'no';
        }
        if (game.makAegis) {
            result.aegises.push('mak');
            result.rating = game.makRating ? 'yes' : 'no';
        }
        return result;
    }

    static convertPlayers(from, to) {
        if (from) {
            from.forEach(org => {
                to.push({
                    id: org.id,
                    name: this.getShortName(org.surname, org.name, org.patronymic)
                });
            });
        }
    }

    static getDlGroup(dl) {
        if (!dl || dl == null || dl < 0) {
            return "-";
        }
        if (dl > 7.74) {
            return "8+";
        }
        return "" + Math.round(dl * 2) / 2;
    }

    static getPriceInfo(game) {
        if (!game.mainPayment) {
            return "-";
        }
        let result = game.mainPayment + " " + this.getCurrency(game.currency);
        if (game.discountedPayment) {
            result = result + " (" + game.discountedPayment + " " + this.getCurrency(game.currency);
            if (game.discountedPaymentReason) {
                result = result + ", " + game.discountedPaymentReason;
            }
            result = result + ")";
        }
        return result;
    }

    static getCurrency(currency) {
        return currency == "r" ? "руб" : (currency == "u" ? "usd" : (currency == "e" ? "eur" : ""));
    }

    static getShortName(n1, n2, n3) {
        return n1 + " " + (n2 ? n2.charAt(0) + "." : "") + (n3 ? n3.charAt(0) + "." : "");
    }
}
