<template>
  <b-card>
    <b-card-text class="small text-muted">
      Найдено игр: {{ gamesCount }}. В списке <b>только</b> игры активные на {{ nowTime }}.
      С помощью фильтра ниже можно уточнить поиск.
      <span v-show="!lsAgreed">
        Вы можете <b-link class="text-decoration-none" v-b-modal.lsAgree>согласиться
        использовать маркировку игр</b-link>: она сохраняет данные в локальное хранилище
        вашего браузера.
      </span>
      <span v-show="lsAgreed">
        Возможность маркировки игр включена. Вы можете <b-link class="text-decoration-none" v-b-modal.lsDisagree>
        отказаться от маркировки</b-link>.
      </span>
      <b-modal centered id="lsAgree" title="Согласны использовать маркировку?"
               cancel-title="Отказаться" ok-title="Согласиться"
               @ok="handleLsAgreeOk">
        <p>
          Согласившись, вы увидите дополнительный блок фильтра, позволяющий показывать строки
          определённой маркировки, а в каждой строке появится возможность маркировки.
        </p>
        <p>
          Данные маркировки будут храниться в локальном хранилище вашего браузера: для этого и
          нужно ваше согласие. Данные <b>не будут</b> отправлены на какой-либо сервер, а значит
          будут доступны только на этом компьютере и только в этом браузере.
        </p>
        <p>
          В любой момент можно отказаться от использования фунционала и очистить хранилище от отметок.
          Обратите внимание, что данные также могут исчезнуть из-за некоторых внешних действий. Например,
          чистка персональных данных браузера.
        </p>
      </b-modal>
      <b-modal centered id="lsDisagree" title="Отказаться от маркировки?"
               cancel-title="Оставить маркировку" ok-title="Отказаться от маркировки"
               @ok="handleLsDisagreeOk">
        <p>Отказавшись от маркировки, вы удалите все свои маркировки без возможности их восстановить.</p>
      </b-modal>
    </b-card-text>
  </b-card>
</template>

<script>
import LsUtils from '../../utils/ls-utils';

export default {
  props: ['gamesCount', 'nowTime'],
  data: function () {
    return {
      lsAgreed: false
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.lsAgreed = LsUtils.isActive();
      this.$root.$emit('onMarkersToggle');
    },
    handleLsAgreeOk() {
      LsUtils.createDefaultLs();
      this.refresh();
    },
    handleLsDisagreeOk() {
      LsUtils.clear();
      this.refresh();
    }
  }
}
</script>
