<template>
  <b-input-group size="sm">
    <!--    <b-input-group-prepend>-->
    <!--      <b-button size="sm" @click="selectType()" variant="secondary">-->
    <!--        <b-icon-gear-fill></b-icon-gear-fill>-->
    <!--      </b-button>-->
    <!--    </b-input-group-prepend>-->
    <b-input-group-prepend is-text>Показать маркеры:</b-input-group-prepend>
    <b-input-group-append>
      <b-button class="count-wrapper" size="sm" v-for="t in types" :key="t.id"
                @click="selectType(t.id)" :variant="variant(t)" v-b-tooltip.hover :title="t.text">
        <b-icon v-if="!!t.icon" :icon="t.icon"></b-icon>
        <span class="count" v-if="gameCounts[t.id] > 0">
          {{ gameCounts[t.id] }}
        </span>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<style>
.count-wrapper {
  position: relative;
}

.count {
  color: #FFFFFF;
  background-color: #999999;
  font-size: 9px;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  bottom: -8px;
  left: 2px;
}
</style>

<script>
import LsUtils from "../../utils/ls-utils";

export default {
  props: ['criteria', 'gameCounts'],
  data: function () {
    return {types: [], markerCounts: {}}
  },
  created() {
    this.$root.$on('onGameMarkerChange', this.onGameMarkerChange);
    this.types = LsUtils.getMarkerTypes();
  },
  methods: {
    onGameMarkerChange(oldId, newId) {
      this.gameCounts[oldId]--;
      if (!this.gameCounts[newId]) this.gameCounts[newId] = 0;
      this.gameCounts[newId]++;
      this.$forceUpdate();
    },
    selectType: function (markerId) {
      const index = this.criteria.markers.indexOf(markerId);
      if (index === -1) {
        this.criteria.markers.push(markerId);
      } else {
        this.criteria.markers.splice(index, 1);
      }
      LsUtils.saveLsMarkers(this.criteria.markers);
    },
    variant(marker) {
      return this.criteria.markers && this.criteria.markers.length > 0
      && this.criteria.markers.includes(marker.id) ? marker.variant : 'light';
    }
  }
}
</script>
