<template>
  <b-button-group>
    <b-button class="btn-cab" v-for="t in types" :key="t.id"
              @click="selectType(t.id)" :variant="variant(t)"
              v-b-tooltip.hover :title="t.text">
      <b-icon v-if="!!t.icon" :icon="t.icon"></b-icon>
    </b-button>
  </b-button-group>
</template>

<style>
.btn-cab {
  font-size: 12px;
  padding: 0 4px 0 4px;
}
</style>

<script>

import LsUtils from "../../utils/ls-utils";

export default {
  props: ['game'],
  data: function () {
    return {types: []}
  },
  created() {
    this.types = LsUtils.DEFAULT_TYPES;
  },
  methods: {
    selectType: function (markerId) {
      this.$root.$emit('onGameMarkerChange', this.game.markerId, markerId);
      this.game.markerId = markerId;
      const games = LsUtils.getLs().games;
      games[this.game.id] = markerId;
      LsUtils.saveLsGames(games);
    },
    variant(marker) {
      return this.game ? this.game.markerId === marker.id
          ? marker.variant : 'light' : marker.variant;
    }
  }
}
</script>
