<template>
  <span>
    <b-row class="pb-2">
      <b-col>
        <b-card>
          <b-card-text class="small text-muted">
          Запрошены игры, которые начались не раньше {{ startDateAfter }}
          и закончились в промежутке с {{ endDateBefore }} по {{ endDateAfter }}.
          Найдено игр: {{gameCount}}.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div ref="graph" style="padding: 0; margin: 0;"></div>
      </b-col>
    </b-row>
  </span>
</template>

<script>
import TimelinesChart from 'timelines-chart';
import moment from "moment";

export default {
  data: function () {
    return {
      now: null,
      playerId: null,
      startDateAfter: '',
      endDateBefore: '',
      endDateAfter: '',
      gameCount: 0,
      scaleDomains: ['+', '-', '0', '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8+'],
      scaleColorsBlue: [
        '#74747a',
        '#e6f2ff', '#cce6ff', '#b3d9ff', '#99ccff', '#80bfff', '#66b3ff',
        '#4da6ff', '#3399ff', '#1a8cff', '#0080ff',
        '#0073e6', '#0066cc', '#0059b3', '#004d99', '#004080', '#003366', '#00264d'],
      scaleColorsGroups: [
        '#ffdf7e', '#4b4b4b',
        '#c6ecc6', '#8cd98c', '#53c653', '#39ac39', '#2d862d', '#194d19',
        '#1a8cff', '#0073e6', '#0059b3', '#004d99',
        '#ffcccc', '#ff8080', '#ff4d4d', '#ff0000', '#b30000', '#660000', '#000000']
    }
  },
  mounted() {
    this.$root.$on('onChgkplanGraphType', this.onChgkplanGraphType);
  },
  methods: {
    onChgkplanGraphType(filter) {
      this.startDateAfter = window.startDateAfter;
      this.endDateBefore = window.endDateBefore;
      this.endDateAfter = window.endDateAfter;
      this.gameCount = window.TOURS.length;
      this.now = filter.now;
      this.playerId = filter.playerId;
      this.refresh();
    },
    refresh() {
      const data = this.prepareData();
      this.$nextTick(() => {
        this.draw(data, this.$refs.graph);
      });
    },
    prepareData() {
      const data = window.TOURS;
      const result = [];
      const groups = {"": data.filter(i => i.typeId != 2)}
      Object.entries(groups).map(g => {
        result.push(this.prepareGroup(g[0], g[1]));
      });
      return result;
    },
    prepareGroup(groupName, games) {
      const labels = games.sort(this.smartSort).map(game => this.prepareLabel(game));
      return {"group": groupName, "data": labels}
    },
    smartSort(a, b) {
      const n = this.now.toDate();
      const aAfter = n > a.end.toDate();
      const bAfter = n > b.end.toDate();
      if (aAfter && !bAfter) {
        return -1;
      }
      if (!aAfter && bAfter) {
        return 1;
      }

      const aBefore = n < a.start.toDate();
      const bBefore = n < b.start.toDate();
      if (aBefore && bBefore) {
        return a.start.toISOString().localeCompare(b.start.toISOString());
      }

      const aIn = n > a.start.toDate() && n < a.end.toDate();
      const bIn = n > b.start.toDate() && n < b.end.toDate();
      if (aIn && !bIn) {
        return -1;
      }
      if (!aIn && bIn) {
        return 1;
      }
      return a.end.toISOString().localeCompare(b.end.toISOString());
    },
    prepareLabel(g) {
      return {
        "label": g.name,
        "data": [{
          "game": g, "timeRange": [g.start.toDate(), g.end.toDate()],
          "val": this.playerId && window.PLAYERS && window.PLAYERS[this.playerId].includes(g.id) ? '+' : g.dlGroup
        }]
      }
    },
    segmentClick(e) {
      console.log(e);
      const id = e.data.game.id;
      window.open("https://rating.chgk.info/tournament/" + id, "_blank")
    },
    segmentTooltip(e) {
      const g = e.data.game;
      const editors = g.editors.map(e => e.name);
      return `<div style='max-width: 250px; font-size: 12px; text-align: left; white-space: normal;'>
      <b>ID:</b> ${g.id}<br/>
      <b>Игра:</b> ${g.name}<br/>
      <b>Сложность:</b> ${g.dl}<br/>
      <b>Вопросы:</b> ${g.questions}<br/>
      <br/>
      <b>Начало:</b> ${g.start.format('D MMMM YYYY (ddd)')}<br/>
      <b>Конец:</b> ${g.end.format('D MMMM YYYY (ddd)')}<br/>
      <b>Длительность:</b> ${Math.floor(moment.duration(g.end.diff(g.start)).asDays())} дн.<br/>
      <b>Окончание:</b> ${g.end.from(this.now)}<br/>
      <br/>
      <b>Редакторы:</b><br/>
      ${editors.join('<br/>')}<br/>
      <br/>
      <b>Стоимость:</b> ${g.priceInfo}
      </div>`;
    },
    draw(data, element) {
      TimelinesChart()(element)
          .width(window.innerWidth > 600 ? window.innerWidth - 40 : 600)
          .maxHeight(2400)
          .maxLineHeight(24)
          .leftMargin(window.innerWidth > 800 ? 100 : 0)
          .rightMargin(window.innerWidth > 800 ? 300 : 200)
          .dateMarker(this.now.toDate())
          .zScaleLabel('Сложность')
          .zQualitative(true)
          // eslint-disable-next-line no-undef
          .zColorScale(d3.scaleOrdinal()
              .domain(this.scaleDomains)
              .range(this.scaleColorsGroups))
          .enableOverview(true)
          .onSegmentClick(this.segmentClick)
          .segmentTooltipContent(this.segmentTooltip)
          .data(data);
    }
  }
}
</script>
